<div class="row">
  <div
    class="col-2"
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    "
  >
    <img
      class="productimage"
      src="{{ product.picPath || 'assets/images/no-image.png' }}"
      onerror="this.src='assets/images/no-image.png'"
    />
    <span class="img-custom-work-order" *ngIf="orderDetails.customized"
      >CUSTOM</span
    >
  </div>
  <div class="col-10">
    <div class="forms-row">
      <div>
        <fieldset [disabled]="!isEditable">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Product</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="product"
              [formControl]="productControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__customer-code">CUST</div>
                  <div class="p5 riva-select__name">Product Name</div>
                  <div class="p5 riva-select__sku">Master SKU</div>
                  <div class="p5 riva-select__description">Description</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__customer-code">CUST</div>
                  <div class="p5 riva-select__name">Product Name</div>
                  <div class="p5 riva-select__sku">Master SKU</div>
                  <div class="p5 riva-select__description">Description</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let product of filteredProducts | async"
                [value]="product"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
                (click)="onProductChange(product)"
                [ngStyle]="
                  product.isRivaProduct && {
                    'background-color': 'rgb(133 124 64)'
                  }
                "
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__customer-code">
                    {{ product.customerCodeNavigation?.customerId }}
                  </div>
                  <div class="p5 riva-select__name">
                    <p>{{ product.productName }}</p>
                  </div>
                  <div class="p5 riva-select__sku">
                    <p>{{ product.sku }}</p>
                  </div>
                  <div class="p5 riva-select__description">
                    <p>{{ product.productDesc }}</p>
                  </div>
                </div>
              </mat-option>
              <mat-option *ngIf="!(filteredProducts | async)?.length">
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Material Code</mat-label>
          <mat-select
            [(ngModel)]="orderDetails.materialCodeID"
            (ngModelChange)="onCheckProductSku()"
            class="material-select"
          >
            <mat-select-trigger>
              <div
                *ngIf="
                  selectedMaterial.material?.multiMetalPrimaryMaterial &&
                  selectedMaterial.material?.multiMetalSecondaryMaterial
                "
                style="display: flex; align-items: center; gap: 1px"
              >
                <div class="multi-material">
                  <div
                    class="primary-material"
                    style="border-width: 9px 20px 0 0"
                    [ngStyle]="{
                      'border-color':
                        '#' +
                        selectedMaterial.material?.multiMetalPrimaryMaterial
                          ?.printColor1 +
                        ' transparent transparent transparent'
                    }"
                  ></div>
                  <div
                    class="secondary-material"
                    style="border-width: 0 0 9px 20px"
                    [ngStyle]="{
                      'border-color':
                        'transparent transparent #' +
                        selectedMaterial.material?.multiMetalSecondaryMaterial
                          ?.printColor1 +
                        ' transparent'
                    }"
                  ></div>
                </div>
                <span
                  >{{
                    selectedMaterial.material?.multiMetalPrimaryMaterial.code
                  }}
                  /
                  {{
                    selectedMaterial.material?.multiMetalSecondaryMaterial.code
                  }}</span
                >
              </div>
              <div
                *ngIf="
                  !selectedMaterial.material?.multiMetalPrimaryMaterial ||
                  !selectedMaterial.material?.multiMetalSecondaryMaterial
                "
                style="display: flex; align-items: center; gap: 1px"
              >
                <div
                  style="
                    width: 20px;
                    height: 10px;
                    border: 1px solid white;
                    background-color: rgb(0, 0, 0);
                    margin-right: 5px;
                  "
                  [ngStyle]="{
                    'background-color':
                      '#' + selectedMaterial.material?.printColor1
                  }"
                ></div>
                <span>{{ selectedMaterial.material.description }}</span>
              </div>
            </mat-select-trigger>
            <mat-option
              *ngFor="let mats of productDetail.materials"
              [value]="mats.materialsCodeID"
              class="material-options"
              style="display: flex; align-items: center"
            >
              <div
                *ngIf="
                  mats.material?.multiMetalPrimaryMaterial &&
                  mats.material?.multiMetalSecondaryMaterial
                "
                style="display: flex; align-items: center; gap: 1px"
              >
                <div class="multi-material">
                  <div
                    class="primary-material"
                    [ngStyle]="{
                      'border-color':
                        '#' +
                        mats.material?.multiMetalPrimaryMaterial?.printColor1 +
                        ' transparent transparent transparent'
                    }"
                  ></div>
                  <div
                    class="secondary-material"
                    [ngStyle]="{
                      'border-color':
                        'transparent transparent #' +
                        mats.material?.multiMetalSecondaryMaterial
                          ?.printColor1 +
                        ' transparent'
                    }"
                  ></div>
                </div>
                <span
                  >{{ mats.material?.multiMetalPrimaryMaterial.code }} /
                  {{ mats.material?.multiMetalSecondaryMaterial.code }}</span
                >
              </div>
              <div
                *ngIf="
                  !mats.material?.multiMetalPrimaryMaterial ||
                  !mats.material?.multiMetalSecondaryMaterial
                "
                style="display: flex; align-items: center; gap: 1px"
              >
                <div
                  style="
                    width: 25px;
                    height: 18px;
                    border: 1px solid white;
                    background-color: rgb(0, 0, 0);
                    margin-right: 5px;
                  "
                  [ngStyle]="{
                    'background-color': '#' + mats.material?.printColor1
                  }"
                ></div>
                <span>{{ mats.material.description }}</span>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>Size</mat-label>
          <mat-select
            [(ngModel)]="orderDetails.productSizesID"
            (ngModelChange)="onCheckProductSku()"
            [disabled]="!isEditable"
          >
            <mat-option
              *ngFor="let size of productDetail.productSizes"
              [value]="size.productsSizesID"
            >
              {{ size.size }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>CI-PO</mat-label>
          <input
            matInput
            [(ngModel)]="orderDetails.cipo"
            [disabled]="!isEditable"
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>QTY ORDERED</mat-label>
          <input
            matInput
            type="number"
            [(ngModel)]="orderDetails.qtyordered"
            [disabled]="!isEditable"
          />
        </mat-form-field>
      </div>
      <div></div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%" disabled>
          <mat-label>ENTRY DATE</mat-label>
          <input
            matInput
            [matDatepicker]="entryDate"
            [(ngModel)]="orderDetails.entryDate"
            disabled
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="entryDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #entryDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>DUE DATE</mat-label>
          <input
            matInput
            [matDatepicker]="dueDate"
            [(ngModel)]="orderDetails.dueDate"
            required
            [disabled]="!isEditable"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dueDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="forms-row">
      <div>
        <mat-form-field appearance="fill" style="width: 100%">
          <mat-label>COMMENT</mat-label>
          <textarea
            matInput
            style="height: 60px"
            [(ngModel)]="orderDetails.comment"
            [disabled]="!isEditable"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="component-materials">
        <div *ngIf="productVariations.length">
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Enamel Variation</mat-label>
            <mat-select
              [(ngModel)]="enamelDetail.enamelID"
              (ngModelChange)="onCheckProductSku()"
              [disabled]="!isEditable"
            >
              <mat-option [value]="0">NO-ENAMEL</mat-option>
              <mat-option
                *ngFor="let item of productVariations"
                [value]="item.enamelID"
              >
                <div style="display: flex; align-items: center">
                  <div
                    style="width: 15px; height: 15px; margin-right: 3px"
                    [ngStyle]="{ 'background-color': item.colorHex }"
                  ></div>
                  <span *ngIf="item.pantoneColor" style="margin-right: 6px"
                    >[{{ item.pantoneColor }}]</span
                  >
                  {{ item.enamelName }}
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <fieldset
          [disabled]="!isEditable"
          *ngIf="productStoneVariations.length"
        >
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Stone Variation</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="productStoneVariation"
              (ngModelChange)="onCheckProductSku()"
              [formControl]="productStoneVariationControl"
              [matAutocomplete]="stoneVariation"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #stoneVariation="matAutocomplete"
              [displayWith]="displayProductStone"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__type">Type</div>
                  <div class="p5 riva-select__color">Color</div>
                  <div class="p5 riva-select__clarity">Clarity</div>
                  <div class="p5 riva-select__size">Size</div>
                  <div class="p5 riva-select__boolean">Lab Grown</div>
                  <div class="p5 riva-select__boolean">Traceable</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__type">Type</div>
                  <div class="p5 riva-select__color">Color</div>
                  <div class="p5 riva-select__clarity">Clarity</div>
                  <div class="p5 riva-select__size">Size</div>
                  <div class="p5 riva-select__boolean">Lab Grown</div>
                  <div class="p5 riva-select__boolean">Traceable</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let variation of filteredProductStoneVariation | async"
                [value]="variation"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__type">
                    {{ variation.stone?.stoneType?.name }}
                  </div>
                  <div class="p5 riva-select__color">
                    {{ variation.stone?.stoneColor?.colorName ?? '-' }}
                  </div>
                  <div class="p5 riva-select__clarity">
                    {{ variation.stone?.stoneClarity?.name ?? '-' }}
                  </div>
                  <div class="p5 riva-select__size">
                    {{
                      variation.overrideStoneSize ||
                        (variation.stone?.stoneSize?.displayText ?? '-')
                    }}
                  </div>
                  <div class="p5 riva-select__boolean">
                    {{ variation.stone?.labGrown ? 'Yes' : 'No' }}
                  </div>
                  <div class="p5 riva-select__boolean">
                    {{ variation.stone?.traceable ? 'Yes' : 'No' }}
                  </div>
                </div>
              </mat-option>
              <mat-option
                *ngIf="!(filteredProductStoneVariation | async)?.length"
              >
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
        <fieldset
          [disabled]="!isEditable"
          *ngIf="productChains.length"
          style="grid-column: 1 / span 2"
        >
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Chain</mat-label>
            <input
              type="text"
              matInput
              [(ngModel)]="productChain"
              (ngModelChange)="onCheckProductSku()"
              [formControl]="productChainControl"
              [matAutocomplete]="productChainAutoComplete"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #productChainAutoComplete="matAutocomplete"
              [displayWith]="displayProductChain"
            >
              <mat-option class="riva-select-header riva-select__stick-header">
                <div class="riva-select">
                  <div class="p5 riva-select__type">Type</div>
                  <div class="p5 riva-select__color">Name</div>
                  <div class="p5 riva-select__size">Size/Length</div>
                </div>
              </mat-option>
              <mat-option class="riva-select-header">
                <div class="riva-select">
                  <div class="p5 riva-select__type">Type</div>
                  <div class="p5 riva-select__color">Name</div>
                  <div class="p5 riva-select__size">Size/Length</div>
                </div>
              </mat-option>
              <mat-option
                [value]="noChain"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__type">No Chain</div>
                  <div class="p5 riva-select__color">&nbsp;</div>
                  <div class="p5 riva-select__size">&nbsp;</div>
                </div>
              </mat-option>
              <mat-option
                *ngFor="let chain of filteredProductChains | async"
                [value]="chain"
                style="padding: 0; border-bottom: 1px solid rgb(48, 48, 48)"
              >
                <div class="country-container riva-select">
                  <div class="p5 riva-select__type">
                    {{ chain.chainType ? 'Finished' : 'Unfinished' }}
                  </div>
                  <div class="p5 riva-select__color">
                    {{ chain.name }}
                  </div>
                  <div class="p5 riva-select__size">
                    {{ chain.size }}
                  </div>
                </div>
              </mat-option>
              <!-- <mat-option *ngIf="!(filteredProductChains | async)?.length">
                <div class="text-center riva-select__not-found">Not Found</div>
              </mat-option> -->
            </mat-autocomplete>
          </mat-form-field>
        </fieldset>
      </div>
      <div></div>
      <div>
        <mat-slide-toggle
          style="margin-top: 14px"
          [(ngModel)]="orderDetails.customized"
          [disabled]="!isEditable"
          >Custom</mat-slide-toggle
        >
        <mat-form-field
          class="search-input"
          appearance="fill"
          style="width: 100%; margin-top: 20px"
          floatLabel="always"
        >
          <mat-label>Customer SKU</mat-label>
          <input
            matInput
            [(ngModel)]="selectedProductSku.customerSKU"
            [disabled]="disableCustomerSku"
          />
        </mat-form-field>
      </div>
      <div>
        <button
          class="btn btn-outline-success"
          style="margin-bottom: 10px"
          (click)="save()"
          [disabled]="!(isEditable && isFormValid)"
        >
          <i class="fa fa-save"></i> Save
        </button>
        <fieldset [disabled]="!isEditable">
          <button
            class="btn btn-outline-danger"
            (click)="onClear()"
            userPermission
            [elementKey]="orderDetailsFeatureKey.clearDetail"
          >
            <i class="fa fa-close"></i> Clear
          </button>
        </fieldset>
      </div>
    </div>
  </div>
</div>
