<div class="riva-findings-order-add">
  <div class="tab-container" *ngIf="!orderDetail.ordersDetailsId">
    <nz-tabset nzType="card" [nzSelectedIndex]="selectedTabIndex">
      <nz-tab
        nzTitle="Jumprings"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.JUMPRINGS
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.JUMPRINGS"
          [columns]="jumpringColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Posts"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.POST
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.POST"
          [columns]="postColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Flyers"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.FLYER
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.FLYER"
          [columns]="flyerColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Springs"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.SPRING
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.SPRING"
          [columns]="springColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Springrings"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.SPRING_RING
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.SPRING_RING"
          [columns]="springRingColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Lobster Claw"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.LOBSTER_CLAW
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.LOBSTER_CLAW"
          [columns]="lobsterClawColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
      <nz-tab
        nzTitle="Tags"
        [nzDisabled]="
          orderDetail.ordersDetailsId > 0 &&
          orderDetail.findingsTypesID !== findingsType.TAGS
        "
      >
        <riva-findings-order-add-item
          [(orderDetail)]="orderDetail"
          [(findings)]="findings"
          [isEditable]="isEditable"
          [findingType]="findingsType.TAGS"
          [columns]="tagColumns"
          (onCancel)="onCancel.emit()"
          (onSaveOrder)="onSaveOrder()"
        ></riva-findings-order-add-item>
      </nz-tab>
    </nz-tabset>
  </div>
  <div *ngIf="orderDetail.ordersDetailsId > 0">
    <riva-findings-order-add-item
      [(orderDetail)]="orderDetail"
      [(findings)]="findings"
      [isEditable]="isEditable"
      [findingType]="orderDetail.findingsTypesID"
      [columns]="getColumns(orderDetail.findingsTypesID)"
      (onCancel)="onCancel.emit()"
      (onSaveOrder)="onSaveOrder()"
    ></riva-findings-order-add-item>
  </div>
</div>
