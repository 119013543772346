import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import { ORDER_DETAILS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/order-details-permission';
import { LoadService } from 'src/app/custom/load-overlay/load-overlay.service';
import { OrdersDetails } from 'src/app/models/orders.model';
import { ChainService } from 'src/app/services/chain.service';
import { ProductBomService } from 'src/app/services/product-bom.service';
import {
  OrderChain,
  OrderChainSize,
  OrderChainStock,
  OrderDetailAlt,
} from '../../riva-chain/riva-chain-finish/models';

@Component({
  selector: 'riva-chain-order',
  templateUrl: './riva-chain-order.component.html',
  styleUrls: ['./riva-chain-order.component.scss'],
})
export class RivaChainOrderComponent implements OnInit {
  @Input() orderDetail: Partial<OrdersDetails>;
  @Input() isEditable: boolean;
  @Output() onCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  chains: OrderChain[] = [];
  selectedChain: OrderChain = {} as OrderChain;
  chainControl = new FormControl();
  filteredChains: Observable<OrderChain[]>;
  chainSizes: OrderChainSize[] = [];
  selectedChainSize: OrderChainSize = {} as OrderChainSize;
  chainStocks: OrderChainStock[] = [];
  orderDetailsFeatureKey = ORDER_DETAILS_FEATURE_KEY;
  isSaving = false;
  showSizeError = false;

  constructor(
    private chainService: ChainService,
    private productBomService: ProductBomService,
    private loadService: LoadService,
    private toastrService: ToastrService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.getChains();
  }

  getChains() {
    forkJoin([
      this.chainService.getChainRaws(),
      this.chainService.getChainFinished(),
    ]).subscribe(([raw, finished]) => {
      const rawMapped = raw.map((r) => ({
        chainId: r.chainRawID,
        chainType: false,
        name: r.styleName,
        sku: r.sku,
        picPath: r.picPath,
        description: r.description,
      }));
      const finishedMapped = finished.map((f) => ({
        chainId: f.chainFinishedID,
        chainType: true,
        name: f.name,
        sku: f.chainRaw?.sku,
        picPath: f.picPath,
        description: f.description,
      }));
      this.chains = [...finishedMapped, ...rawMapped];

      this.filteredChains = this.chainControl.valueChanges.pipe(
        startWith(this.selectedChain.name),
        map((value) => this._filterProductChain(value)),
      );

      if (this.orderDetail?.productsId) {
        this.selectedChain =
          this.chains.find(
            (c) =>
              c.chainId === this.orderDetail.productsId &&
              c.chainType === this.orderDetail.chainType,
          ) ?? ({} as OrderChain);
        if (this.selectedChain.chainId) {
          this.getChainSizes();
          this.getChainMaterials();
        }
      }
    });
  }
  private _filterProductChain(name: string): OrderChain[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.chains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue) ||
          option.sku?.toLowerCase().includes(filterValue) ||
          option.description?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['name'];
      return this.chains.filter(
        (option) =>
          option.name?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          option.sku?.toLowerCase().includes(filterValue?.toLowerCase()) ||
          option.description
            ?.toLowerCase()
            .includes(filterValue?.toLowerCase()),
      );
    }
    return this.chains;
  }
  displayChain(item: OrderChain): string {
    if (!item?.chainId) return '';
    const labels = [];
    labels.push(item?.chainType ? 'Finished' : 'Unfinished');
    if (item?.name) labels.push(item.name);
    if (item?.sku) labels.push(item.sku);
    return labels.join(' | ');
  }

  getChainSizes() {
    if (this.selectedChain.chainId > 0 && this.selectedChain.chainType) {
      this.chainService
        .getChainFinishedSizes(this.selectedChain.chainId)
        .pipe(
          map((sizes) =>
            sizes.map((size) => ({
              chainSizeId: size.chainFinishedSizesID,
              size: size.size,
            })),
          ),
        )
        .subscribe((data = []) => {
          this.chainSizes = data;
          if (!this.orderDetail?.productSizesID && data.length === 1) {
            this.orderDetail.productSizesID = data[0].chainSizeId;
            this.getChainMaterials();
          }
        });
    }
  }

  getChainMaterials() {
    const chainId =
      this.orderDetail.productsId > 0
        ? this.orderDetail.productsId
        : this.selectedChain.chainId;
    if (chainId && this.selectedChain.chainType) {
      this.chainService
        .getChainFinishedMaterials(chainId)
        .pipe(
          map((mats) =>
            mats.map((mat) => ({
              chainFinishedStockID: mat.chainFinishedMaterialsID,
              materialCodesID: mat.materialsCodeID,
              material: mat.material,
            })),
          ),
        )
        .subscribe((data = []) => {
          this.chainStocks = data;
          if (!this.orderDetail.materialCodeID && data.length === 1) {
            this.orderDetail.materialCodeID = data[0].materialCodesID;
          }
        });
    } else if (chainId) {
      this.chainService
        .getChainRawStock(chainId)
        .pipe(
          map((stock) =>
            stock.map((stock) => ({
              chainFinishedStockID: stock.chainRawStockID,
              materialCodesID: stock.materialCodesID,
              material: stock.material,
            })),
          ),
        )
        .subscribe((data = []) => {
          this.chainStocks = data;
          if (!this.orderDetail.materialCodeID && data.length === 1) {
            this.orderDetail.materialCodeID = data[0].materialCodesID;
          }
        });
    }
  }

  onSelectChain(chain: OrderChain) {
    this.orderDetail.productSizesID = 0;
    this.chainSizes = [];

    this.orderDetail.materialCodeID = 0;
    this.chainStocks = [];
    this.getChainSizes();
    this.getChainMaterials();
  }

  onSaveOrderDetail() {
    const order: OrderDetailAlt = {
      orderDetailsAltID: this.orderDetail.ordersDetailsId ?? 0,
      ordersID: this.orderDetail.ordersId,
      chain_FinishedID: this.selectedChain.chainType
        ? this.selectedChain.chainId
        : 0,
      chain_FinishedSizesID: this.selectedChain.chainType
        ? this.orderDetail.productSizesID
        : 0,
      chain_RawID: this.selectedChain.chainType
        ? 0
        : this.selectedChain.chainId,
      chain_RawSizesID: this.selectedChain.chainType
        ? 0
        : this.orderDetail.productSizesID,
      materialCodeID: this.orderDetail.materialCodeID,
      qtyOrdered: this.orderDetail.qtyordered,
      dueDate: this.orderDetail.dueDate
        ? DateTime.fromJSDate(new Date(this.orderDetail.dueDate)).toFormat(
            'yyyy-MM-dd',
          )
        : null,
      comment: this.orderDetail.comment,
      cipo: this.orderDetail.cipo,
      customized: this.orderDetail.customized,
      chainRawLength: this.selectedChain.chainType
        ? 0
        : this.orderDetail.chainRawLength,
    };
    this.chainService.setChainOrderDetail(order).subscribe(() => {
      this.onSuccess.emit();
    });
  }

  onCancelEvent() {
    this.onCancel.emit();
  }

  get selectedMaterial() {
    return (
      this.chainStocks.find(
        (m) => m.materialCodesID === this.orderDetail.materialCodeID,
      )?.material ?? { printColor1: '', description: '' }
    );
  }

  get isFormValid() {
    return (
      this.selectedChain.chainId &&
      this.orderDetail.qtyordered &&
      this.orderDetail.dueDate &&
      this.orderDetail.materialCodeID &&
      (!this.selectedChain.chainType || this.orderDetail.productSizesID)
    );
  }

  onClear() {
    this.orderDetail = {
      ...this.orderDetail,
      productsId: 0,
      chainType: false,
      materialCodeID: 0,
      productSizesID: 0,
      chainRawLength: 0,
      cipo: '',
      qtyordered: 1,
      comment: '',
      customized: false,
    };
    this.selectedChain = { name: '' } as OrderChain;
    this.chainSizes = [];
    this.chainStocks = [];
  }
}
