import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ORDER_DETAILS_FEATURE_KEY } from 'src/app/core/user-permission/user-permission-rules/order-details-permission';
import { OrdersDetails } from 'src/app/models/orders.model';
import { OtherComponentsService } from 'src/app/services/other-components.service';
import {
  OtherComponent,
  OtherComponentMaterial,
  OtherComponentSize,
  OtherComponentType,
} from '../../other-components/model';
import { OrderDetailAlt } from '../../riva-chain/riva-chain-finish/models';

@Component({
  selector: 'riva-other-component-order',
  templateUrl: './riva-other-component-order.component.html',
  styleUrls: ['./riva-other-component-order.component.scss'],
})
export class RivaOtherComponentOrderComponent implements OnInit {
  @Input() orderDetail: Partial<OrdersDetails>;
  @Input() isEditable: boolean;
  @Output() onCancel = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  otherComponents: OtherComponent[] = [];
  selectedOtherComponent: OtherComponent = {} as OtherComponent;
  otherComponentMaterials: OtherComponentMaterial[] = [];
  selectedOtherComponentMaterial: OtherComponentMaterial =
    {} as OtherComponentMaterial;
  otherComponentTypes: OtherComponentType[] = [];
  otherComponentSizes: OtherComponentSize[] = [];
  selectedOtherComponentSize: OtherComponentSize = {} as OtherComponentSize;
  otherComponentControl = new FormControl();
  filteredOtherComponents: Observable<OtherComponent[]>;
  orderDetailsFeatureKey = ORDER_DETAILS_FEATURE_KEY;
  isSaving = false;

  constructor(private otherComponentsService: OtherComponentsService) {}

  ngOnInit(): void {
    this.getOtherComponents();
  }

  getOtherComponents() {
    this.otherComponentsService.getOtherComponents().subscribe((data = []) => {
      this.otherComponents = data;
      this.filteredOtherComponents =
        this.otherComponentControl.valueChanges.pipe(
          startWith(this.selectedOtherComponent.longName),
          map((value) => this._filterOtherComponent(value)),
        );

      if (this.orderDetail?.productsId) {
        this.selectedOtherComponent =
          this.otherComponents.find(
            (i) => i.otherComponentsID === this.orderDetail.productsId,
          ) ?? ({} as OtherComponent);
        if (this.selectedOtherComponent?.otherComponentsID) {
          this.getOtherComponentMaterials(
            this.selectedOtherComponent?.otherComponentsID,
          );
          this.getOtherComponentSizes(
            this.selectedOtherComponent?.otherComponentsID,
          );
        }
      }
    });
  }

  getOtherComponentMaterials(otherComponentsID = 0) {
    this.otherComponentMaterials = [];
    if (otherComponentsID === 0) return;
    this.otherComponentsService
      .getOtherComponentsMaterials(otherComponentsID)
      .subscribe((data) => {
        this.otherComponentMaterials = data;
      });
  }

  getOtherComponentSizes(otherComponentsID = 0) {
    this.otherComponentSizes = [];
    if (otherComponentsID === 0) return;
    this.otherComponentsService
      .getOtherComponentsSizes(otherComponentsID)
      .subscribe((data) => {
        this.otherComponentSizes = data;
      });
  }

  private _filterOtherComponent(name: string): OtherComponent[] {
    if (name !== undefined && typeof name === 'string') {
      const filterValue = name?.toLowerCase();
      return this.otherComponents.filter(
        (option) =>
          option.typeDisplay?.toLowerCase().includes(filterValue) ||
          option.longName?.toLowerCase().includes(filterValue) ||
          option.shortName?.toLowerCase().includes(filterValue) ||
          option.description?.toLowerCase().includes(filterValue),
      );
    } else if (typeof name === 'object') {
      const filterValue: string = name['longName'];
      return this.otherComponents.filter(
        (option) =>
          option.typeDisplay?.toLowerCase().includes(filterValue) ||
          option.longName?.toLowerCase().includes(filterValue) ||
          option.shortName?.toLowerCase().includes(filterValue) ||
          option.description?.toLowerCase().includes(filterValue),
      );
    }
    return this.otherComponents;
  }

  displayOtherComponentName(item: OtherComponent): string {
    if (!item?.otherComponentsID) return '';
    const labels = [];
    labels.push(item.typeDisplay);
    if (item?.longName) labels.push(item.longName);
    if (item?.shortName) labels.push(item.shortName);
    return labels.join(' | ');
  }

  onSelectOtherComponent(item: OtherComponent) {
    this.orderDetail.productSizesID = 0;
    this.otherComponentSizes = [];

    this.orderDetail.materialCodeID = 0;
    this.otherComponentMaterials = [];
    this.getOtherComponentMaterials(item.otherComponentsID);
    this.getOtherComponentSizes(item.otherComponentsID);
  }

  onSaveOrderDetail() {
    const order: OrderDetailAlt = {
      orderDetailsAltID: this.orderDetail.ordersDetailsId ?? 0,
      ordersID: this.orderDetail.ordersId,
      chain_FinishedID: 0,
      chain_FinishedSizesID: 0,
      chain_RawID: 0,
      chain_RawSizesID: 0,
      materialCodeID: this.orderDetail.materialCodeID,
      qtyOrdered: this.orderDetail.qtyordered,
      dueDate: this.orderDetail.dueDate
        ? DateTime.fromJSDate(new Date(this.orderDetail.dueDate)).toFormat(
            'yyyy-MM-dd',
          )
        : null,
      comment: this.orderDetail.comment,
      cipo: this.orderDetail.cipo,
      customized: this.orderDetail.customized,
      chainRawLength: 0,
      otherComponentsID: this.selectedOtherComponent.otherComponentsID,
      otherComponentsSizesID: this.orderDetail.productSizesID,
    };
    this.otherComponentsService.setOrderDetail(order).subscribe(() => {
      this.onSuccess.emit();
    });
  }

  onCancelEvent() {
    this.onCancel.emit();
  }

  get selectedMaterial() {
    return (
      this.otherComponentMaterials.find(
        (m) => m.materialsCodeID === this.orderDetail.materialCodeID,
      )?.materialCode ?? { printColor1: '', description: '' }
    );
  }

  get isFormValid() {
    return (
      this.selectedOtherComponent.otherComponentsID &&
      this.orderDetail.qtyordered &&
      this.orderDetail.dueDate &&
      this.orderDetail.materialCodeID &&
      this.orderDetail.productSizesID
    );
  }

  onClear() {
    this.orderDetail = {
      ...this.orderDetail,
      productsId: 0,
      chainType: false,
      materialCodeID: 0,
      productSizesID: 0,
      chainRawLength: 0,
      cipo: '',
      qtyordered: 1,
      comment: '',
      customized: false,
    };
    this.selectedOtherComponent = { longName: '' } as OtherComponent;
    this.otherComponentMaterials = [];
    this.otherComponentSizes = [];
  }
}
