import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Finding, FindingsStock } from 'src/app/components/riva-findings/model';
import { OrdersDetails } from 'src/app/models/orders.model';
import { FindingsService } from 'src/app/services/findings.service';

@Component({
  selector: 'riva-findings-order-add-item',
  templateUrl: './riva-findings-order-add-item.component.html',
  styleUrls: ['./riva-findings-order-add-item.component.scss'],
})
export class RivaFindingOrderAddItemComponent implements OnInit, AfterViewInit {
  @Input() orderDetail: Partial<OrdersDetails> = {};
  @Input() findings: Finding[];
  @Input() isEditable: boolean = true;
  @Input() isSaving: boolean = false;
  @Input() findingType: number;
  @Input() columns: Record<string, string>; // Key and Label (name: 'Name')
  @Output() onCancel = new EventEmitter();
  @Output() onSaveOrder = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;


  findingsByType = new MatTableDataSource<Finding>([]);
  stocks: FindingsStock[] = [];
  displayedColumns = [];

  constructor(private findingsService: FindingsService) {}

  ngOnInit(): void {
    this.displayedColumns = Object.keys(this.columns);
  }
  ngAfterViewInit(): void {
      this.findingsByType.sort = this.sort;
  }

  ngOnChanges(): void {
    this.findingsByType.data =
      this.findings?.filter((f) => f.findingsTypesID === this.findingType) ??
      [];
    if (this.orderDetail.findingsID) {
      const finding = this.findingsByType.data.find(
        (tag) => tag.findingsId === this.orderDetail.findingsID,
      );
      if (finding) {
        this.onSelectFinding(finding, true);
      }
    }
  }
  onSelectFinding(finding: Finding, override = false) {
    if (this.isEditable || override) {
      this.orderDetail.findingsID = finding.findingsId;
      this.orderDetail.findingsSizesID = finding.findingSizesID;
      this.orderDetail.findingsTypesID = finding.findingsTypesID;
      this.orderDetail.picPath = finding.picPath;
      this.getStockBySizeId();
    }
  }

  getStockBySizeId() {
    if (!this.orderDetail.findingsSizesID) {
      return;
    }
    this.findingsService
      .getFindingsStockBySizeId(this.orderDetail.findingsSizesID)
      .subscribe((data) => {
        this.stocks = data ?? [];
      });
  }
  get isFormValid() {
    return (
      this.orderDetail.findingsID &&
      this.orderDetail.findingsSizesID &&
      this.orderDetail.findingsTypesID &&
      this.orderDetail.materialCodeID &&
      this.orderDetail.dueDate
    );
  }
}
